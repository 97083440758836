<template>
    <div class="bg-white dark:bg-purple-800 text-purple-900 dark:text-white shadow-md rounded-lg xl:rounded-2xl mb-4 lg:mb-6 2xl:mb-8">
        <div @click="toggleOpen" class="relative cursor-pointer p-5 md:p-8 lg:p-9 2xl:p-10 pr-20">
            <h5 class="text-lg md:text-xl lg:text-2xl 2xl:text-3xl font-bold leading-1.6">{{ heading }}</h5>

            <span class="text-3xl lg:text-4xl font-bold absolute right-6 lg:right-10 top-3.5 md:top-6 lg:top-8 2xl:top-9" v-html="open ? '-' : '+'"></span>
        </div>
        <div ref="contentWrapper" class="transition-height duration-500 overflow-hidden h-0">
            <div class="text-base md:text-lg lg:text-xl 2xl:text-2.5xl -tracking-p1 leading-1.8 md:leading-1.8 lg:leading-1.8 2xl:leading-1.8 p-5 pt-0 md:p-8 md:pt-0 lg:p-9 lg:pt-0 2xl:p-10 2xl:pt-0 ">
                <slot/>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, watch, nextTick } from 'vue';

const props = defineProps({
    heading: String,
    open: Boolean
});

const contentWrapper = ref(null);
const emit = defineEmits(['toggleItem']);

const toggleOpen = () => {
    emit('toggleItem', !props.open);
};

watch(() => props.open, (newOpen) => {
    nextTick(() => {
        if (newOpen) {
            contentWrapper.value.style.height = `${contentWrapper.value.scrollHeight}px`;
        } else {
            contentWrapper.value.style.height = '0';
        }
    });
});
</script>
