<template>
    <div class="overflow-hidden w-full mb-4 py-8 lg:py-16">
        <div class="flex flex-row gap-4 pr-4 w-[200%] h-full animate-marquee items-stretch">
            <template v-for="n in 2">
                <div 
                    v-for="testimonial in Object.values(testimonials)" 
                    :key="testimonial.name" 
                    class="flex flex-1 gap-4 text-left"
                >
                    <div class="flex flex-col gap-y-4 xl:gap-y-12 bg-white shadow-xl p-6 md:p-8 rounded-xl h-full justify-between min-w-[280px] sm:min-w-[320px] lg:min-w-[400px] xl:min-w-[500px] dark:bg-purple-800 dark:text-white"
                    :class="{
                        'dark:bg-purple-950' : invert
                    }">
                        <div class="">
                            <p class="text-md lg:text-lg xl:text-xl 2xl: 1.5xl:text-2xl" v-html="testimonial.quote"></p>
                        </div>
                        <div class="flex items-center gap-x-4">
                            <div class="relative w-12 h-12 rounded-full overflow-hidden">
                                <img :src="testimonial.image" class="w-full h-full object-cover"/>
                            </div>
                            <p class="text-lg lg:text-lg xl:text-xl 1.5xl:text-2xl font-bold font-heading">{{ testimonial.name }}</p>
                        </div>
                    </div>
                </div>
            </template>
        </div>
  </div>
</template>

<script setup>

defineProps({
    invert: Boolean
});

const testimonials = {
    '1': {
        'name': 'Gary Bailey',
        'quote': '“<strong>After only 1 week, my Kong ads have cut my CPL in half.</strong> For the past 6 months my ad spend has been at $100 per day and cost per lead has been around $10. Now I\'m getting twice the leads for $4.27 per lead! This will pay for itself 10,000x over!!”', 
        'image': '/img/testimonial-gary-bailey.png'
    },
    '2': {
        'name': 'Krste Kovaceski',
        'quote': '<strong>“I\'ve been tinkering with Al copywriting like a mad scientist in a lab full of word-bots,</strong> and let me tell you, I thought I was close to cracking the code. But your Kong Al? It\'s like comparing a tricycle to a Tesla.”',
        'image': '/img/testimonial-krste-kovaceski.png'
    },
    '3': {
        'name': 'Corey Edmonds',
        'quote': '<strong>“I got my Meta Ads rocking using Kong.</strong> More leads in the first 1hr than l\'ve ever seen in months 😂😂”',
        'image': '/img/testimonial-corey-edmonds.png'
    },
    '4': {
        'name': 'Amanda Cullity',
        'quote': '<strong>“Kong is amazing!</strong> Just got my Facebook ads website leads down to 49 cents per lead!”',
        'image': '/img/testimonial-amanda-cullity.png'
    },
    '5': {
        'name': 'Waheed Dehedhi',
        'quote': '<strong>“Was very skeptical... what can this do that ChatGPT or other Als can\'t do.</strong> signed up. tried it. yup not sceptical any more. I now have a mini sabri suby writer on my team. WATCH OUT!””',
        'image': '/img/testimonial-waheed-dehedhi.png'
    }
}

</script>